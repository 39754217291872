import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Container from "react-bootstrap/Container";

const privacyPolicy = () => (
    <Layout>
        <SEO title="Kebijakan Privasi" />
        <section>
            <Container>
                <h1>Kebijakan Privasi</h1>
                <p>
                    CV. Anugerah Terang Dunia berkomitmen terhadap keamanan dan perlindungan privasi bagi pengguna situs ini. Tidak satu pun data yang dikumpulkan oleh situs ini
                    dibagikan ke pihak ketiga.
                </p>
                <h2>Informasi yang dikumpulkan</h2>
                <p>
                    Saat anda menggunakan situs ini, kami mengirimkan <i>cookies</i> ke peramban pengguna. Hal ini adalah hal yang umum di internet. <i>Cookies</i> ini hanya
                    digunakan untuk keperluan statistik (untuk mengukur lalu lintas dan perilaku pengguna situs) dan untuk menyesuaikan navigasi. <i>Cookies</i> yang dikirimkan
                    tidak akan digunakan untuk mengidentifikasi setiap pengguna. <i>Cookies</i> ini dapat dihapus kapan saja dan situs ini dapat dioperasikan dengan <i>cookies</i>{" "}
                    yang dinonaktifkan. Periksa peramban anda untuk konfigurasi pengaturan ini.
                </p>
                <h2>Informasi yang disediakan oleh pengguna</h2>
                <p>
                    Pada halaman hubungi kami terdapat formulir kontak dimana pengguna dapat mengirimkan informasi seperti nama dan email jika mereka ingin menghubungi atau
                    dihubungi oleh CV. Anugerah Terang Dunia misalnya informasi spesifik atau berlangganan buletin (<i>newsletter</i>). Kami hanya menggunakan informasi ini untuk
                    berkomunikasi dengan pengguna dan tidak ada data yang dibagikan ke pihak ketiga.
                </p>
            </Container>
        </section>
    </Layout>
);


export default privacyPolicy;
